import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import HeroWithImage from "../components/heroWithImage/index";
import Seo from "../components/seo";
import HighLights from "../containers/project/HighLights";
import Overview from "../containers/project/Overview";
import AboutTheProject from "../containers/project/About";
import AdditionalFigures from "../containers/project/AdditionalFigures";

import heroDesktop from "../assets/heroDesktop/Tocantinzinho.png";
import heroMobile from "../assets/heroMobile/Tocantinzinho.png";

const OkoWest = ({ data }) => {
  const {
    title,
    highlightsTitle,
    highlightsCard1Title,
    highlightsCard1Text,
    highlightsCard2Title,
    highlightsCard2Text,
    highlightsCard3Title,
    highlightsCard3Text,
    overviewTitle,
    overviewText,
    figure1Text,
    figure1Image,
    figure2Text,
    figure2Image,
    aboutTitle,
    aboutTab1Title,
    aboutTab1Content,
    aboutTab2Title,
    aboutTab2Content,
    aboutTab3Title,
    aboutTab3Content,
    aboutTab4Title,
    aboutTab4Content1,
    aboutTab4Table1,
    aboutTab4Content2,
    aboutTab4Table2,
    aboutTab4Table2Notes,
    aboutTab4Content3,
    aboutTab4Table3,
    aboutTab4Table3Notes,
    additionalFiguresTitle,
    figure3Text,
    figure3Image,
    figure4Text,
    figure4Image,
    figure5Text,
    figure5Image,
  } = data.contentfulOkoProjectPage;

  return (
    <Layout inverted>
      <Seo title="OKO WEST" />
      <HeroWithImage title={title} heroMobile={heroMobile} heroDesktop={heroDesktop} />
      <HighLights
        title={highlightsTitle}
        card1Title={highlightsCard1Title}
        card1Text={highlightsCard1Text}
        card2Title={highlightsCard2Title}
        card2Text={highlightsCard2Text}
        card3Title={highlightsCard3Title}
        card3Text={highlightsCard3Text}
      />
      <Overview
        title={overviewTitle}
        text={overviewText}
        figures={[
          {
            text: figure1Text.figure1Text,
            image: figure1Image,
          },
          {
            text: figure2Text.figure2Text,
            image: figure2Image,
          },
        ]}
      />
      <AboutTheProject
        aboutTitle={aboutTitle}
        tab1Title={aboutTab1Title}
        tab1ContentTitle={aboutTab1Title}
        tab1Content={aboutTab1Content}
        tab2Title={aboutTab2Title}
        tab2ContentTitle={aboutTab2Title}
        tab2Content={aboutTab2Content}
        tab3Title={aboutTab3Title}
        tab3ContentTitle={aboutTab3Title}
        tab3Content={aboutTab3Content}
        tab4Title={aboutTab4Title}
        tab4ContentTitle={aboutTab4Title}
        okoTab4Content1={aboutTab4Content1}
        okoTab4Content2={aboutTab4Content2}
        okoTab4Content3={aboutTab4Content3}
        okoTable1Data={aboutTab4Table1}
        okoTable2Data={{ ...aboutTab4Table2, notes: aboutTab4Table2Notes }}
        okoTable3Data={{ ...aboutTab4Table3, notes: aboutTab4Table3Notes }}
      />
      <AdditionalFigures
        title={additionalFiguresTitle}
        figure1={{ text: figure3Text.figure3Text, image: figure3Image }}
        figure2={{ text: figure4Text.figure4Text, image: figure4Image }}
        figure3={{ text: figure5Text.figure5Text, image: figure5Image }}
      />
    </Layout>
  );
};

export const query = graphql`
  query OkoWestPageQuery($locale: String) {
    contentfulOkoProjectPage(node_locale: { eq: $locale }) {
      title
      highlightsTitle
      highlightsCard1Title
      highlightsCard1Text
      highlightsCard2Title
      highlightsCard2Text
      highlightsCard3Title
      highlightsCard3Text
      overviewTitle
      overviewText {
        raw
      }
      figure1Text {
        figure1Text
      }
      figure1Image {
        title
        file {
          url
        }
      }
      figure2Text {
        figure2Text
      }
      figure2Image {
        title
        file {
          url
        }
      }
      aboutTitle
      aboutTab1Title
      aboutTab1Content {
        raw
      }
      aboutTab2Title
      aboutTab2Content {
        raw
      }
      aboutTab3Title
      aboutTab3Content {
        raw
      }
      aboutTab4Title
      aboutTab4Content1 {
        raw
      }
      aboutTab4Table1 {
        title
        columns
        rows {
          category
          subcategories {
            name
            updated_mre_tonnage_kt
            updated_mre_au_grade_gt
            updated_mre_contained_gold_koz
            june23_mre_contained_gold_koz
            june23_mre_au_grade_gt
          }
        }
      }
      aboutTab4Content2 {
        raw
      }
      aboutTab4Table2 {
        title
        columns
        rows {
          category
          zones {
            zone
            tonnage_kt
            au_grade_gt
            contained_gold_koz
          }
        }
      }
      aboutTab4Table2Notes {
        raw
      }
      aboutTab4Content3 {
        raw
      }
      aboutTab4Table3 {
        title
        columns
        rows {
          category
          weathering_profiles {
            profile
            tonnage_kt
            au_grade_gt
            contained_gold_koz
          }
        }
      }
      aboutTab4Table3Notes {
        raw
      }
      additionalFiguresTitle
      figure3Text {
        figure3Text
      }
      figure3Image {
        title
        file {
          url
        }
      }
      figure4Text {
        figure4Text
      }
      figure4Image {
        title
        file {
          url
        }
      }
      figure5Text {
        figure5Text
      }
      figure5Image {
        title
        file {
          url
        }
      }
    }
  }
`;

export default OkoWest;
